import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useState, useEffect } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { HeaderBar } from './shared';
import { cancelReserved } from '../api/reservation';
import dayjs from 'dayjs';
import {
  aprroveCateringInvoice,
  declineCateringInvoice,
  getCateringInvoices
} from '../api/catering';
import { formatNumberToCurrency } from '../utils/currency';

const CateringInv = ({ isNavigationDrawerOpen, onMenuButtonClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cateringInvoices, setCateringInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const fetchApi = async () => {
    setIsLoading(true);
    try {
      const { data } = await getCateringInvoices();
      setCateringInvoices(data);
    } catch (error) {
      console.error(error);
      setCateringInvoices([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const paymentStatus = {
    100: 'Pending',
    200: 'Paid',
    201: 'Finished',
    300: 'User Canceled',
    301: 'Admin Canceled',
    401: 'Expired',
    500: 'Failed'
  };
  const paymentStatusColor = {
    100: '#ffc107',
    200: '#4caf50',
    201: '#2196f3',
    300: '#e91e63',
    301: '#e91e63',
    401: 'black',
    500: 'black'
  };

  const handleClickMenu = (event) => {
    const id = event.target.getAttribute('target');
    setAnchorEl(event.currentTarget);
    setActiveId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAprrove = () => {
    if (activeId) {
      const approveApi = async () => {
        try {
          setIsWaiting(true);
          await aprroveCateringInvoice(activeId);
        } catch (error) {
          console.error(error);
        } finally {
          setAnchorEl(null);
          fetchApi();
          setIsWaiting(false);
        }
      };
      approveApi();
    }
  };

  const handleDecline = () => {
    if (activeId) {
      const declineApi = async () => {
        try {
          await declineCateringInvoice(activeId);
        } catch (error) {
          console.error(error);
        } finally {
          setAnchorEl(null);
          fetchApi();
        }
      };

      declineApi();
      setAnchorEl(null);
    }
  };

  return (
    <>
      <HeaderBar
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        onMenuButtonClick={onMenuButtonClick}
        title={`Catering Invoice`}
      />
      <Grid container item column={12}>
        <Grid item xs={12} container columns={12} spacing={2} sx={{ p: '80px 36px 0px 36px' }}>
          <Grid item xs={6}>
            <Card sx={{ p: '20px' }}>
              <Typography>Pending Orders</Typography>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ p: '20px' }}>
              <Typography>Paid Orders</Typography>
            </Card>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '200px' }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} container columns={12} spacing={2} sx={{ p: '0px 36px 80px 36px' }}>
            <Grid item xs={6}>
              <Grid container columns={1} sx={{ mt: '44px' }} rowGap={2}>
                {cateringInvoices
                  .filter((item) => item.status === 100)
                  .map((data, index) => {
                    let { customerName, grandTotal, payment, plan, status, createdAt, id } = data;

                    const dateTime = dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss');
                    return (
                      <Grid item xs={1} key={index}>
                        <Card sx={{ p: '14px 20px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mb: '12px'
                            }}>
                            <Typography sx={{ fontSize: '12px' }}>{dateTime}</Typography>

                            <Box sx={{ cursor: 'pointer' }}>
                              <MoreHorizIcon
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClickMenu}
                                target={id}
                              />
                            </Box>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}>
                              <MenuItem disabled={isWaiting} onClick={handleAprrove}>
                                Approve
                              </MenuItem>
                              <MenuItem disabled={isWaiting} onClick={handleDecline}>
                                Decline
                              </MenuItem>
                            </Menu>
                          </Box>
                          <Box sx={{}}>
                            <Typography sx={{ fontWeight: '600' }}>{customerName}</Typography>
                          </Box>
                          <Divider />
                          <Box
                            sx={{
                              mt: '20px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 0.5
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Catering Plan</Typography>
                              <Typography>{plan}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'start'
                              }}>
                              <Typography>Grand Total </Typography>
                              <Typography sx={{ maxWidth: '70%' }}>
                                {formatNumberToCurrency(grandTotal)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Payment Method </Typography>
                              <Typography>{payment}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Status</Typography>
                              <Typography
                                sx={{
                                  color: paymentStatusColor[status],
                                  fontWeight: '600'
                                }}>
                                {paymentStatus[status]}
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container columns={1} sx={{ mt: '44px' }} rowGap={2}>
                {cateringInvoices
                  .filter((item) => item.status === 200)
                  .map((data, index) => {
                    let { customerName, grandTotal, plan, payment, status, createdAt, id } = data;

                    const dateTime = dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss');
                    return (
                      <Grid item xs={1} key={index}>
                        <Card sx={{ p: '14px 20px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mb: '12px'
                            }}>
                            <Typography sx={{ fontSize: '12px' }}>{dateTime}</Typography>
                          </Box>
                          <Box sx={{}}>
                            <Typography sx={{ fontWeight: '600' }}>{customerName}</Typography>
                          </Box>
                          <Divider />
                          <Box
                            sx={{ mt: '20px', display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Catering Plan</Typography>
                              <Typography>{plan}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'start'
                              }}>
                              <Typography>Grand Total </Typography>
                              <Typography sx={{ maxWidth: '70%' }}>
                                {formatNumberToCurrency(grandTotal)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Payment Method </Typography>
                              <Typography>{payment}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Typography>Status</Typography>
                              <Typography
                                sx={{
                                  color: paymentStatusColor[status],
                                  fontWeight: '600'
                                }}>
                                {paymentStatus[status]}
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CateringInv;
